/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { Grid, Button, Space, Modal, Radio, Tabs, Image  } from 'antd-mobile'
import { useLocation, useSearchParams, useHistory } from 'react-router-dom';
import { ContentTitle } from 'pages/recognition/result'
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { mkResultShareUrl, getResultUrlHasTitle, getResultUrlIsShared, getResultUrlOptime, getUnderApproval, getUrlIsUnderApproval } from "model/recognition/recognition"
import { getUrlIsInnerJump } from 'model/information/info'
import { SHARE_TITLE } from "model/util/comm"
import { COLOR } from 'component/comm';
import { HasBabyIcon, HasNoBabyIcon } from 'component/icons'


// -----------------------------------------------------------------------
const HcgContent = forwardRef((props = {}, ref) => {
    let { title, titleColor, personalityTab, suggestTab, hasBaby, url, hasTitle, opTime, isShare, isInnerJump } = props

    const shareOnClickHd = () => {
        const shareUrl = mkResultShareUrl(url, opTime)
        let desc = "我分享了我的早孕试纸检测结果-->" + title + "，快来看吧！"
        if (hasBaby) {
            sendSharedToApp(shareUrl, SHARE_TITLE.description(SHARE_TITLE.HCG_BABY), desc)
        }else{
            sendSharedToApp(shareUrl, SHARE_TITLE.description(SHARE_TITLE.HCG_NO_BABY), desc)
        }
    }

    return (
        <>
            <ContentTitle title={title} titleColor={titleColor} url={url} hasTitle={hasTitle} opTime={opTime} 
            shareOnClickHd={shareOnClickHd} isShare={isShare} isInnerJump={isInnerJump}/>

            <div className='resultBG'>
                <div className='pic'>
                    {hasBaby ? <HasBabyIcon style={{fontSize: "128px"}}/> : <HasNoBabyIcon style={{fontSize: "128px"}}/>}
                </div>

                <div className="triangle"></div>
                <div className='row_space_big'/>
                <div className='row_space_small'/>

                <Tabs 
                    activeLineMode='fixed'
                    style={{
                        '--title-font-size': '16px',
                        '--fixed-active-line-width': '30px',
                    }}
                >
                    <Tabs.Tab title="温馨提示" key="personality">
                        <div className='resultTab'>{personalityTab}</div>
                    </Tabs.Tab>

                    <Tabs.Tab title="营养关注" key="suggest">
                        <div className='resultTab'>{suggestTab}</div>
                    </Tabs.Tab>

                </Tabs>
            </div>

        </>
    )
})


const HcgWeek0Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">备孕阶段的营养非常重要，它直接关系到未来宝宝的健康和妈妈的身体状况。</div>
            <div className="text">备孕期间还应该避免一些有害物质，如酒精、烟草和某些药物。同时，保持健康的生活方式，如规律的锻炼和充足的睡眠，也非常重要。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、叶酸：这是非常重要的营养素，可以帮助预防宝宝神经管缺陷。建议在备孕前三个月就开始补充叶酸。</div>
            <div className="text">2、铁质：铁是制造红细胞的重要成分，可以预防贫血。可以通过红肉、绿叶蔬菜和豆类来补充。</div>
            <div className="text">3、钙质：钙对于宝宝的骨骼和牙齿发育至关重要。牛奶、奶酪和绿叶蔬菜都是很好的钙来源。</div>
            <div className="text">4、蛋白质：蛋白质对于宝宝的组织生长非常重要。可以通过鸡肉、鱼肉、豆类和坚果来摄取。</div>
            <div className="text">5、健康脂肪：Omega-3脂肪酸对宝宝的脑部和眼睛发育非常重要。可以通过食用鱼类、亚麻籽和核桃来补充。</div>
            <div className="text">6、维生素D：维生素D有助于钙的吸收和利用，对于骨骼健康至关重要。可以通过日晒和食物如鱼类、蛋黄来摄取。</div>
            <div className="text">7、纤维：纤维有助于保持消化系统的健康，可以预防便秘。全谷物、蔬菜和水果都是很好的纤维来源。</div>
            <div className="text">8、水分：保持充足的水分摄入对于身体的各项功能都至关重要。</div>
        </>
    )

    let displayTitle = "未孕"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={false}
            url="/result/dsiplay/HCG/week0"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const HcgWeek1Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">孕妈可以适当远离人群密集的地方，室内也要勤通风，保持空气流通。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、营养丰富全面，保证每天的饮食结构合理，配餐表中要尽量包括主食（米、面或其他杂粮），有色蔬菜（红、黄、绿色）与水果、鱼、肉、禽、蛋、奶及豆制品；食用油；调味品；坚果类食品，等等。这样才能均衡膳食，保证营养。</div>
            <div className="text">2、每天清晨空腹喝一杯新鲜的白开水或矿泉水，可以起到洗涤体内器官的作用，而且对改善器官功能，防止一些疾病的发生都有很大好处。</div>
        </>
    )

    let displayTitle = "早孕一周内"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={true}
            url="/result/dsiplay/HCG/week1"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const HcgWeek2Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">孕妈从孕早期时，就要养成规律的作息时间，早睡早起。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、为保证宝宝神经系统的正常发育，要多吃富含叶酸的食品，如樱桃、桃、李、杏红果等新鲜水果中都含有丰富的叶酸，不妨根据自己的喜好酌情选用，也可遵医嘱补充叶酸片剂。</div>
            <div className="text">2、补充叶酸同时，加强多种微量元素的摄取，因为微量元素锌、铜等也参与了中枢神经系统的发育。可以适当滴吃一些香蕉、动物内脏，还有瓜子、花生、松子等坚果类食品，这些食品中富含锌元素。</div>
        </>
    )

    let displayTitle = "早孕一至二周"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={true}
            url="/result/dsiplay/HCG/week2"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const HcgWeek3Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">孕妈尽量避免颠簸劳累，也尽量少弯腰。适当吃些核桃，促进宝宝大脑发育哦~</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、这一周要保证充足的热能和优质蛋白质的供给，还要摄入充足的无机盐、微量元素和适量的维生素，如钙、铁、铜、碘、及维生素A和D等。</div>
            <div className="text">2、血虚、贫血的准妈妈，可适当吃些红枣、枸杞、红小豆、动物血、肝脏等；易疲劳、感冒的准妈妈，可适当吃些黄芪、人参、西洋参等；脾胃较虚弱的准妈妈，可适当吃些山药、莲子、白扁豆。</div>
        </>
    )

    let displayTitle = "早孕二至三周"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={true}
            url="/result/dsiplay/HCG/week3"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})


const HcgWeek4Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">一些准妈妈会出现妊娠反应，比如恶心、孕吐啦，孕妈可以适当喝点柠檬水缓解。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、三次正餐做到定时定量。特助时期（有早孕反应和孕中后期）可以一天5~6餐，甚至可以想吃就吃。一定要吃早餐，而且要保证质量。但是喜欢吃油条的一定要改掉，因为炸油条使用的明矾中含铝，铝颗通过胎盘侵入胎儿大脑，影响胎儿致力发育。</div>
            <div className="text">2、早孕反映可能降临。早孕反映在不同的人身上有不同的表现，开始和持续的时间也不尽相同。准妈妈一定要坚信自己能克服这些不适，并说服自己尽量吃些东西。</div>
        </>
    )

    let displayTitle = "早孕三至四周"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={true}
            url="/result/dsiplay/HCG/week4"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )


    
})

const HcgWeekBigPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">孕准妈妈可以适当进行一些胎教，可以每天午休一小时，保证睡眠充足，最重要是保持心情愉悦哦~</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">1、选择的食物要易消化、易吸收，同时能减轻呕吐，如烤面包、饼干、大米或小米稀饭及营养煲粥。干食品能减轻恶心、呕吐的症状，稀饭能补充因恶心、呕吐失去的水分</div>
            <div className="text">2、多吃核桃、海鱼、黑木耳有助于胎儿神经系统的发育。</div>
            <div className="text">3、为了克服晨吐症状，早晨可以在床边准备一杯水、一片面包，或一小块水果、几粒花生，它们会帮你抑制强烈的恶心。</div>
        </>
    )

    let displayTitle = "早孕四周以上"

    return (
        <HcgContent 
            title={displayTitle}
            titleColor={COLOR.GRAY_13}
            hasBaby={true}
            url="/result/dsiplay/HCG/weekBig"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

// -----------------------------------------------------------------------
export { HcgWeek0Page, HcgWeek1Page, HcgWeek2Page, HcgWeek3Page, HcgWeek4Page, HcgWeekBigPage }

