import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory, useParams } from 'react-router-dom';
import { qryCourse, courseCollectQry, courseCollect, courseCollectCancel } from 'model/course/course'
import { mkInfoShareUrl, getWebConfigFromUuid, getUrlIsInnerJump } from "model/information/info"
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { getResultUrlIsShared } from "model/recognition/recognition"
import { BackIcon, ShareIcon } from 'component/icons'
import { BackupTitle } from 'pages/utils/utils'

// ----------------------------------------------------------------------------------
const CourseDetailPage = forwardRef((props = {}, ref) => {
    const [displayCollect, setDisplayCollect] = useSafeState(false)
    const [collected, setCollected] = useSafeState(false)

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)
    const isInner = getUrlIsInnerJump(searchParams)
    const history = useHistory()
    let { uuid } = useParams() 
    if (uuid.length > 32) {
        uuid = uuid.substring(0, 32)
    }

    let detailRef = useRef()

    const shareOnClickHd = () => {
        if (detailRef?.current) {
            const courseInfo = detailRef?.current?.getCourseInfo()
            if (courseInfo) {
                const shareUrl = mkInfoShareUrl(courseInfo?.ShareUrl)
                sendSharedToApp(shareUrl, courseInfo?.Title, courseInfo?.Description)
            }
        }
    }

    const backOnClickHd = () => {
        if (isInner) {
            history.goBack()
        }else{
            sendBackToApp("")
        }
    }

    const collectOnClickHd = () => {
        console.log("@@@@@@@--> collect clicked!")
        let srcCollected = collected
        
        setTimeout(async () => {
            if (srcCollected) {
                let ret = await courseCollectCancel(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }else{
                let ret = await courseCollect(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }
        }, 1)
    }

    const getTitleContent = () => {
        if (isShare) {
            return undefined
        }

        return (
            <div className='startLine'>
                <BackupTitle 
                backOnClickHd={backOnClickHd} 
                shareOnClickHd={shareOnClickHd} 
                collectOnClickHd={collectOnClickHd}
                displayCollect={displayCollect}
                collected={collected}
                />
            </div>
        )



        return (
            <div className='startLine'>
                <div className='row_left'>
                    <div style={{display: "flex", flex: 1}}>
                        <div onClick={backOnClickHd}>
                            <BackIcon style={{fontSize: "24px"}} />
                        </div>     
                    </div>
                    <div className='row_right'>
                    <div onClick={shareOnClickHd} style={{marginRight: "16px"}}>
                        <ShareIcon style={{fontSize: "20px"}}/>
                    </div>
                </div>
                
                </div>
            </div>
        )
    }

    useMount(async () => {
        // 收藏按钮处理
        const collectInfo = await courseCollectQry(uuid)
        if (collectInfo) {
            setDisplayCollect(collectInfo.display)
            setCollected(collectInfo.collected)
        }
    })

    return (
        <>
            {getTitleContent()}
            <CourseDetailContent uuid={uuid} ref={detailRef}/>
        </>
    )
})

const CourseDetailContent = forwardRef((props = {}, ref) => {
    let { uuid } = props

    const [rawData, setRawData] = useSafeState()
    let courseRef = useRef()

    const getContent = () => {
        let tmp = BraftEditor.createEditorState(rawData)
        return tmp.toHTML()
    }

    useMount(async () => {
        const data = await getWebConfigFromUuid(uuid)
        if (data) {
            setRawData(data)
        }

        const tmpInfo = await qryCourse(uuid)
        if (tmpInfo) {
            courseRef.current = tmpInfo
            console.log("$$$$$$---> tmpInfo = %o", tmpInfo)
        }
    })

    let getCourseInfo = useMemoizedFn(() => {
        return courseRef?.current
    }, [])

    useImperativeHandle(ref, () => ({
        getCourseInfo
    }), [getCourseInfo]);

    return (
        <>
            <div className="braft-output-content" dangerouslySetInnerHTML={{__html: getContent()}} />
        </>
    )
})


// ----------------------------------------------------------------------------------
export { CourseDetailPage, CourseDetailContent }

