

let PRODUCT_TYPE = {
    AOB: "AOB", 
    CORTISOL: "Cortisol", 
    HCG: "HCG", 
    LIST: [
        {value: "AOB", label: "ABO血型检测"}, 
        {value: "Cortisol", label: "Cortisol压力值"}, 
        {value: "HCG", label: "早孕试纸"}, 
    ], 
    description: (value) => PRODUCT_TYPE.LIST.find(l => l.value === value)?.label,
}


// -------------------------------------------------------------------------------------
export { PRODUCT_TYPE }

